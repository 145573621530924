import { SET_BOOKMARK, SET_BOOKMARKS, RESET_BOOKMARK, DELETE_BOOKMARK, UPDATE_BOOKMARK, CREATE_BOOKMARK } from "../actions/types";

const defaultState = {
  bookmark: {
    users: [],
    tags: [],
  },
  bookmarks: [],
};

const bookmarkReducer = (state = { ...defaultState }, { type, payload }) => {
  switch (type) {
    case UPDATE_BOOKMARK:
      return {
        ...state,
        bookmark: { ...payload },
        bookmarks: [...state.bookmarks.map(bookmark => (bookmark._id === payload._id ? payload : bookmark))],
      };
    case CREATE_BOOKMARK:
      return {
        ...state,
        bookmark: { ...payload },
        bookmarks: [...state.bookmarks, payload],
      };
    case SET_BOOKMARK:
      return {
        ...state,
        bookmark: { ...payload },
      };
    case DELETE_BOOKMARK:
      return {
        ...state,
        bookmark: { ...defaultState.bookmark },
        bookmarks: [...state.bookmarks.filter(bookmark => bookmark._id !== payload.id)],
      };
    case RESET_BOOKMARK:
      return {
        ...state,
        bookmark: { ...defaultState.bookmark },
      };
    case SET_BOOKMARKS:
      return {
        ...state,
        bookmarks: [...payload],
      };
    default:
      return state;
  }
};

export default bookmarkReducer;
