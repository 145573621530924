import { uniqueId } from "lodash";
import { isNumber } from "./OptimizerUtils";

export function removeExtraHolesAlongLineBore($, part, FlipSideOp, issues) {
  console.log("*************************************************************************");
  console.log("Looking for extra holes along a line bore");

  // add a uuid to each line bore

  const lineBores = $(part)
    .find(`OperationLineBore[FlipSideOp="${FlipSideOp}"]`)
    .toArray()
    .map(hole => $(hole).attr("uuid", uniqueId()));

  const cabNumber = isNumber($(part).attr("AssyNo")) ? "R1C" + $(part).attr("AssyNo") : $(part).attr("AssyNo");

  const removedLineBores = []; // must be up here

  lineBores.forEach(lineBore => {
    // Get the properties of the current line bore

    const uuid = $(lineBore).attr("uuid");
    const diameter = Number($(lineBore).attr("Diameter"));
    const marginOfError = diameter;

    const lineBoreStart = Number($(lineBore).attr("X")) - marginOfError;
    const qty = Number($(lineBore).attr("Quan"));
    // @TODO: Confirm 32 is a valid hardcode. is this ever different?
    const length = qty * 32;
    // Note: The length includes an extra 32mm beyond the last hole, therefore we adjust this when calculating the end
    const lineBoreEnd = lineBoreStart + length - 32 + marginOfError * 2;
    const lineBoreY = Number($(lineBore).attr("Y"));
    const depth = Number($(lineBore).attr("Depth"));
    const isDrilledThrough = depth >= 19;

    console.log({ lineBoreStart, lineBoreEnd });

    // iterate each hole to confirm it does not fall along the path of the line bore

    const holes = $(part).find(`OperationHole[FlipSideOp="${FlipSideOp}"]`).toArray();
    holes.forEach(hole => {
      const holeX = Number($(hole).attr("X"));
      const holeY = Number($(hole).attr("Y"));
      const depth = Number($(hole).attr("Depth"));

      // ignore holes that are not along the same Y axis as the line bore

      if (holeY < lineBoreY - marginOfError || holeY > lineBoreY + marginOfError) return;

      // if the hole falls along the line bore then we should remove it
      if (holeX >= lineBoreStart && holeX <= lineBoreEnd) {
        // if the hole is drilled through then we need to keep it, but we should adjust the X and Y to match the closest hole on the line bore

        if (depth >= 19) snapHoleToClosestLineBoreHole($, part, hole, lineBore);
        else {
          console.log("Removing hole along line bore: ");
          console.log({ X: holeX, Y: holeY });
          issues.push({
            type: "removed overlapping hole along line bore",
            msg: `REMOVING HOLE ON LINE BORE: ${$(part).attr("Name")} (${cabNumber}) at position X: ${$(hole).attr("X")}, Y: ${$(hole).attr("Y")}, Depth: ${$(hole).attr("Depth")}`,
          });

          $(hole).remove();
        }
      }
    });

    // iterate each lineBore to confirm it does not fall along the path of the line bore

    if (removedLineBores.includes(uuid) !== true) {
      const lineBoresToCheck = lineBores.filter(lb => $(lb).attr("uuid") !== uuid && removedLineBores.includes($(lb).attr("uuid")) !== true);

      lineBoresToCheck.forEach(lineBoreToCheck => {
        const lineBoreToCheckStart = Number($(lineBoreToCheck).attr("X")) - marginOfError;
        const lineBoreToCheckLength = Number($(lineBoreToCheck).attr("Quan")) * 32;
        const lineBoreToCheckEnd = lineBoreToCheckStart + lineBoreToCheckLength - 32 + marginOfError * 2;
        const lineBoreToCheckY = Number($(lineBoreToCheck).attr("Y"));
        const lineBoreToCheckQty = Number($(lineBoreToCheck).attr("Quan"));
        const lineBoreToCheckUuid = $(lineBoreToCheck).attr("uuid");

        // ignore line bores that are not along the same Y axis as the line bore

        if (lineBoreToCheckY < lineBoreY - marginOfError || lineBoreToCheckY > lineBoreY + marginOfError) return;

        // if the line bore falls along the line bore then we should remove it

        if (lineBoreToCheckStart >= lineBoreStart && lineBoreToCheckStart <= lineBoreEnd) {
          console.log("found overlapping line bore along line bore");
          console.log({ X: lineBoreToCheckStart, Y: lineBoreToCheckY });

          // need to compare the length of the line bores and remove the shorter one
          if (lineBoreToCheckQty < qty) {
            console.log("Removing line bore along line bore: ");
            issues.push({
              type: "removed overlapping line bore along line bore",
              msg: `REMOVING LINE BORE ON LINE BORE: ${$(part).attr("Name")} (${cabNumber}) at position X: ${$(lineBoreToCheck).attr("X")}, Y: ${$(lineBoreToCheck).attr("Y")}, Qty: ${$(
                lineBoreToCheck
              ).attr("Quan")}`,
            });
            $(lineBoreToCheck).remove();
            removedLineBores.push(lineBoreToCheckUuid);
          } else {
            console.log("Removing line bore along line bore: ");
            issues.push({
              type: "removed overlapping line bore along line bore",
              msg: `REMOVING LINE BORE ON LINE BORE: ${$(part).attr("Name")} (${cabNumber}) at position X: ${$(lineBore).attr("X")}, Y: ${$(lineBore).attr("Y")}, Qty: ${$(lineBore).attr("Quan")}`,
            });
            $(lineBore).remove();
            removedLineBores.push(uuid);
          }
        } else if (lineBoreToCheckEnd >= lineBoreStart && lineBoreToCheckEnd <= lineBoreEnd) {
          if (lineBoreToCheckQty < qty) {
            console.log("Removing line bore along line bore: ");
            issues.push({
              type: "removed overlapping line bore along line bore",
              msg: `REMOVING LINE BORE ON LINE BORE: ${$(part).attr("Name")} (${cabNumber}) at position X: ${$(lineBoreToCheck).attr("X")}, Y: ${$(lineBoreToCheck).attr("Y")}, Qty: ${$(
                lineBoreToCheck
              ).attr("Quan")}`,
            });
            $(lineBoreToCheck).remove();
            removedLineBores.push(lineBoreToCheckUuid);
          } else {
            console.log("Removing line bore along line bore: ");
            issues.push({
              type: "removed overlapping line bore along line bore",
              msg: `REMOVING LINE BORE ON LINE BORE: ${$(part).attr("Name")} (${cabNumber}) at position X: ${$(lineBore).attr("X")}, Y: ${$(lineBore).attr("Y")}, Qty: ${$(lineBore).attr("Quan")}`,
            });
            $(lineBore).remove();
            removedLineBores.push(uuid);
          }
        }
      });
    }
  });
}

function snapHoleToClosestLineBoreHole($, part, hole, lineBore) {
  const lineBoreStart = Number($(lineBore).attr("X"));
  const lineBoreY = Number($(lineBore).attr("Y"));
  const lineBoreQuantity = Number($(lineBore).attr("Quan"));
  const lineBoreHoleXPositions = [];
  for (let i = 0; i < lineBoreQuantity; i++) {
    const lineBoreHoleX = lineBoreStart + i * 32;
    lineBoreHoleXPositions.push(lineBoreHoleX);
  }
  const holeX = Number($(hole).attr("X"));
  const holeY = Number($(hole).attr("Y"));
  const closestLineBoreHoleX = lineBoreHoleXPositions.reduce((a, b) => {
    return Math.abs(b - holeX) < Math.abs(a - holeX) ? b : a;
  });
  const closestLineBoreHoleY = lineBoreY;
  console.log("Snapping hole to closest line bore hole: ");
  console.log({ X: holeX, Y: holeY });
  console.log({ X: closestLineBoreHoleX, Y: closestLineBoreHoleY });
  $(hole).attr("X", closestLineBoreHoleX);
  $(hole).attr("Y", closestLineBoreHoleY);
}

// Add 12" top shelf hole to cut back gable
// if (sourceLib.includes("Reach-in Closets") && maxLineBoreQuantity > 15 && (name == "Gable (CB)" || name == "Gable (L)")) {
//   const X = maxLength - 100;
//   const OperationHole = `<OperationHole Diameter="5" Diameter_Eq="" ID="1" X="${X}" Y="264.7" Depth="13" Hide="False" X_Eq="" Y_Eq="" Depth_Eq="" Hide_Eq="" IsUserOp="True" IdTag="9999" Noneditable="False" Anchor="" FlipSideOp="False" Custom_Added="True" />`;
//   $(part).find("Operations").append(OperationHole);
//   console.log(`*** Added OperationHole to ${name}, PartID: ${partId} (${cabNumber})`);
//   console.log(OperationHole);
// } else if (sourceLib.includes("Reach-in Closets") && maxLineBoreQuantity > 15 && name == "Gable (R)") {
//   const X = maxLength - 100;
//   const Y = minLength - 264.7;
//   const OperationHole = `<OperationHole Diameter="5" Diameter_Eq="" ID="1" X="${X}" Y="${Y}" Depth="13" Hide="False" X_Eq="" Y_Eq="" Depth_Eq="" Hide_Eq="" IsUserOp="True" IdTag="9999" Noneditable="False" Anchor="" FlipSideOp="False" Custom_Added="True" />`;
//   $(part).find("Operations").append(OperationHole);
//   console.log(`*** Added OperationHole to ${name}, PartID: ${partId} (${cabNumber})`);
//   console.log(OperationHole);
// }
