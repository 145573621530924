import { findClosest, roundDownTo16th, roundNearest16th } from "../../../utils/general";
import { Part } from "./Part";

export class Kick extends Part {
  // @TODO: Should FaceKicks be rounded down to nearest 1/16th?
  getPrintName() {
    const printName = super.getPrintName();
    console.log(printName);
    return printName;
  }
  getWidth() {
    return roundNearest16th(this.getPartAttribute("W"), "mm");
  }
  getLength() {
    return roundNearest16th(this.getPartAttribute("L"), "mm");
  }
}

export class Filler extends Part {
  getWidth() {
    return roundNearest16th(this.getPartAttribute("W"), "mm");
  }
  getLength() {
    return roundNearest16th(this.getPartAttribute("L"), "mm");
  }
}

export class ClosetRod extends Part {
  // Swapped "L" and "W" in old mozaik to fix the LnFT calculation errors caused by the length of the rod showing up in the width column
  getWidth() {
    const isNewMozaik = this.getIsNewMozaik();
    if (isNewMozaik) return roundNearest16th(this.getPartAttribute("W"), "mm");
    else return roundNearest16th(this.getPartAttribute("L"), "mm"); // swapped in old mozaik
  }
  getLength() {
    const isNewMozaik = this.getIsNewMozaik();
    const deduction = 6.35; // deduct 1/4" (6.35mm) off the rod length";
    if (isNewMozaik) return roundNearest16th(this.getPartAttribute("L"), "mm") - deduction;
    else return roundNearest16th(this.getPartAttribute("W"), "mm"); // swapped in old mozaik
  }
  getMaterial(printName) {
    return this.getPartRef()[printName || this.getPrintName()]["Material"];
  }
  getStyle() {
    const productInteriorValue = this.getProductInterior().attr("ClosetRodOR");
    const style = productInteriorValue !== "0" ? productInteriorValue : this.getRoomSetting("ClosetRod");
    return style === "Mozaik Default" ? this.getMaterial() : style;
  }
  getIsNewMozaik() {
    const productInteriorValue = this.getProductInterior().attr("ClosetRodOR");
    const style = productInteriorValue !== "0" ? productInteriorValue : this.getRoomSetting("ClosetRod");
    return style && style !== "Mozaik Default";
  }
  getPrintName() {
    const isNewMozaik = this.getIsNewMozaik();
    if (isNewMozaik === false) return super.getPrintName();
    else {
      const productInteriorValue = this.getProductInterior().attr("ClosetRodOR");
      const style = productInteriorValue !== "0" ? productInteriorValue : this.getRoomSetting("ClosetRod");
      return `${style.replaceAll("-", "|").exclude("(NO OPS) ")}`;
    }
  }
}

export class ShoeFence extends Part {
  getWidth() {
    const shoeFenceRef = [17, 23, 29, 35];
    const width = this.getPartAttribute("W");
    return findClosest(width, shoeFenceRef);
  }
  getPrintName() {
    const width = this.getWidth();
    return `Shoe Fence | ${width} | Chrome`; // @TODO: allow for dynamic material
  }
}

export class CabProdPart extends Part {
  async isExcluded(printName) {
    printName = printName || this.getPrintName();
    const kickStyle = this.Product.getKickStyle();
    const quantity = this.getPartAttribute("Quan");
    const secondaryCategory = await this.getSecondaryCategory();

    const excludedSecondaryCategories = ["Door", "Drawer", "Tray"];
    const excludedPrintNames = ["Rollout Shelf", "Hanger"];

    if (excludedPrintNames.includes(printName) || excludedSecondaryCategories.includes(secondaryCategory)) return true;
    if (["Front Toe", "Front Kick"].includes(printName) && kickStyle === "Face") return true;
    if (quantity <= 0) return true;

    return false;
  }

  async getPart(printName) {
    printName = printName || this.getPrintName();
    const secondaryCategory = await this.getSecondaryCategory();

    if (printName === "Shoe Fence") return new ShoeFence(this.PartElement, this.Product);
    if (secondaryCategory === "Kick") return new Kick(this.PartElement, this.Product);
    if (secondaryCategory === "Filler") return new Filler(this.PartElement, this.Product);
    if (secondaryCategory === "Closet Rod") return new ClosetRod(this.PartElement, this.Product);
    return new Part(this.PartElement, this.Product);
  }
}
