import { SET_USER, SET_USERS, UPDATE_USER, RESET_USER, USER_LOADED, UPDATE_BOOKMARK, DELETE_BOOKMARK, CREATE_BOOKMARK } from "../actions/types";

const defaultState = {
  user: {},
  users: [],
};

const userReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case SET_USER:
    case USER_LOADED:
      return {
        ...state,
        user: { ...payload },
      };
    case UPDATE_USER:
      return {
        ...state,
        user: state.user._id === payload._id ? { ...payload } : { ...state.user },
        users: [...state.users.map(user => (user._id === payload._id ? payload : user))],
      };
    case SET_USERS:
      return {
        ...state,
        users: [...payload],
      };
    case RESET_USER:
      return {
        ...state,
        user: defaultState.user,
      };
    case UPDATE_BOOKMARK:
      const updatedBookmarkUsers = payload.users.map(user => user._id);
      return {
        ...state,
        user: {
          ...state.user,
          bookmarks: [...state.user.bookmarks.map(bookmark => (bookmark._id === payload._id ? payload : bookmark))],
        },
        users: [
          ...state.users.map(user => {
            if (updatedBookmarkUsers.includes(user._id)) {
              return {
                ...user,
                bookmarks: [...state.user.bookmarks.map(bookmark => (bookmark._id === payload._id ? payload : bookmark))],
              };
            } else {
              return user;
            }
          }),
        ],
      };
    case CREATE_BOOKMARK:
      const createdBookmarkUsers = payload.users.map(user => user._id);
      return {
        ...state,
        user: {
          ...state.user,
          bookmarks: [...state.user.bookmarks, payload],
        },
        users: [
          ...state.users.map(user => {
            if (createdBookmarkUsers.includes(user._id)) {
              return {
                ...user,
                bookmarks: [...user.bookmarks, payload],
              };
            } else {
              return user;
            }
          }),
        ],
      };
    case DELETE_BOOKMARK:
      return {
        ...state,
        user: {
          ...state.user,
          bookmarks: [...state.user.bookmarks.filter(bookmark => bookmark._id !== payload.id)],
        },
        users: [
          ...state.users.map(user => {
            return {
              ...user,
              bookmarks: [...user.bookmarks.filter(bookmark => bookmark._id !== payload.id)],
            };
          }),
        ],
      };
    default:
      return state;
  }
};

export default userReducer;
