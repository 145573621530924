import React from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";

const PrivateRoute = ({ auth, children }) => {
  const { isAuthenticated, loading } = auth;
  return <>{!isAuthenticated && !loading ? <Navigate to="/login" /> : children}</>;
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
