import { SET_TAG, SET_TAGS, RESET_TAG, DELETE_TAG } from '../actions/types';

const defaultState = {
  tag: {},
  tags: [],
};

const tagReducer = (state = { ...defaultState }, { type, payload }) => {
  switch (type) {
    case SET_TAG:
      return {
        ...state,
        tag: {
          ...payload,
        },
      };
    case DELETE_TAG:
      return {
        ...state,
        tag: { ...defaultState.tag },
        tags: [...state.tags.filter(tag => tag._id !== payload.id)],
      };
    case RESET_TAG:
      return {
        ...state,
        tag: { ...defaultState.tag },
      };
    case SET_TAGS:
      return {
        ...state,
        tags: [...payload],
      };
    default:
      return state;
  }
};

export default tagReducer;
