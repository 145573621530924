import { findClosest, roundDownToArrayValue } from "../../../utils/general";
import { Part } from "./Part";

export class Drawer extends Part {
  // LINK ../examples/Drawer.xml

  getDrawerElement() {
    return this.PartElement;
  }
  getDrawerFace() {
    const DrawerFront = this.getDrawerElement().find("DrawerFront");
    return new DrawerFace(this.PartElement, this.Product, DrawerFront);
  }
  getDrawerPull() {
    return new DrawerPull(this.PartElement, this.Product);
  }
  getDrawerBoxSides() {
    const DrawerSide = this.getDrawerElement().find("DrawerProdPart[Type=DrawerSide]");
    return new DrawerBoxSides(this.PartElement, this.Product, DrawerSide);
  }
  getDrawerBoxFrontBack() {
    const DrawerBoxFront = this.getDrawerElement().find("DrawerProdPart[Type=DrawerBack]");
    return new DrawerBoxFrontBack(this.PartElement, this.Product, DrawerBoxFront);
  }
  getDrawerBoxBottom() {
    const DrawerBottom = this.getDrawerElement().find("DrawerProdPart[Type=DrawerBottom]");
    return new DrawerBoxBottom(this.PartElement, this.Product, DrawerBottom);
  }
  getDrawerSlides() {
    const DrawerSide = this.getDrawerElement().find("DrawerProdPart[Type=DrawerSide]");
    return new DrawerSlides(this.PartElement, this.Product, DrawerSide);
  }
  getFileRails() {
    const DrawerSide = this.getDrawerElement().find("DrawerProdPart[Type=DrawerSide]");
    return new FileRails(this.PartElement, this.Product, DrawerSide);
  }
  getFileCorners() {
    return new FileCorners(this.PartElement, this.Product);
  }
  getDrawerDividers() {
    const DrawerSide = this.getDrawerElement().find("DrawerProdPart[Type=DrawerSide]");
    return new DrawerDividers(this.PartElement, this.Product, DrawerSide);
  }
  getDrawerLock() {
    return new DrawerLock(this.PartElement, this.Product);
  }
  getDrawerFelt() {
    return new DrawerFelt(this.PartElement, this.Product);
  }
  getDrawerJewelryInsert() {
    return new JewelryInsert(this.PartElement, this.Product);
  }
  // @TODO: Return error is there is no drawer box style (missing drawer box!)
  getBoxStyle() {
    return this.getDrawerElement().attr("DrawerBoxStyle");
  }
  getStyle() {
    return this.getBoxStyle();
  }
  getQuantity() {
    return 1;
  }
}

export class DrawerFace extends Drawer {
  constructor(PartElement, Product, DrawerFront) {
    super(PartElement, Product);
    this.DrawerFrontElement = DrawerFront;
  }

  getDrawerFrontElement() {
    return this.DrawerFrontElement;
  }
  getPrintName() {
    return "Drawer Face | " + this.getDrawerFrontElement().attr("DoorStyle");
  }
  checkIfBottomDrawer() {
    // if Drawer Z value is within 2 inches (~60mm) of Bottom Shelf, then it is a Bottom Drawer
    const bottomShelf = this.findProductPart('CabProdPart[ReportName="Bottom"]');
    const bottomShelfZ = bottomShelf.attr("Z");
    const drawerZ = this.getDrawerElement().attr("BottomZ");
    return Math.abs(drawerZ - bottomShelfZ) <= 60;
  }
  getDrawerFaceHeight() {
    const library = this.Product.getProductLibrary();

    if (library === "Wall Units") {
      return Math.floor(this.getDrawerFrontElement().attr("W"));
    }

    // Drawer Face Height - Override to conform with the companies preferred practices (based on Drawer sheet)
    const drawerFaceHeights = Object.keys(this.getDrawerFaceHeightRef());
    const mozaikHeight = this.getDrawerFrontElement().attr("W");
    const drawerFaceHeight = findClosest(mozaikHeight, drawerFaceHeights);
    const FLRevB = this.getMozaikParam("FLRevB");
    const isBottomDrw = this.checkIfBottomDrawer();

    // If FLRevB = 0 and isBottomDrw, then add 3/8" (~9mm when rounded down) to Standard Size for Bottom Drawer
    const faceAdjustment = FLRevB == 0 && isBottomDrw ? 9 : 0;
    return Number(drawerFaceHeight) + Number(faceAdjustment);
  }
  getDrawerFaceWidth() {
    // Use shelf width to pull standard face width, else use mozaik value
    const bottomShelf = this.findProductPart('CabProdPart[ReportName="Bottom"]');

    const shelfWidth = bottomShelf.attr("L")?.to("inch");
    const getDrawerFaceWidthRef = this.getDrawerFaceWidthRef();
    const FLRevS = this.getMozaikProductParam("FLRevS");

    // If FLRevS = 0, then use Mozaik value rounded down to nearest 1mm
    if (FLRevS) return Math.floor(this.getDrawerFrontElement().attr("H"));
    // otherwise, use the shelf width to pull the drawer face width from the reference
    else if (getDrawerFaceWidthRef[shelfWidth]) return getDrawerFaceWidthRef[shelfWidth];
    // otherwise, use the mozaik value rounded down to the nearest 1mm
    else return Math.floor(this.getDrawerFrontElement().attr("H"));
  }
  getLength() {
    return this.getDrawerFaceHeight();
  }
  getWidth() {
    return this.getDrawerFaceWidth();
  }
  getMaterial() {
    const SectAC = this.attr("SectAC"); // "A" or "C"
    const frontFaceValue = this.getFrontFace().attr("MatDoorOR" + SectAC);
    const productValue = this.getProductAttribute("MatDoorOR");
    const roomValue = this.getRoomSetting("MatDoorTemplate");
    console.log({ frontFaceValue, productValue, roomValue });
    const material = frontFaceValue || productValue || roomValue;
    // @TODO: Should change this to be more specific door face material
    if (material === "Thermofoil") {
      return "Thermofoil | " + super.getMaterial().exclude("0.75 inch ").exclude("3-4 ");
    }
    return '3/4" | ' + material.exclude("SLAB").exclude("0.75 inch ").exclude("3-4 ").trim();
  }
}

export class DrawerPull extends Drawer {
  getPrintName() {
    return "Drw Pull | " + this.getStyle();
  }
  getStyle() {
    return this.getDrwPullStyle();
  }
  getWidth() {
    return 0;
  }
  getLength() {
    return 0;
  }
  getMaterial() {
    const printName = this.getPrintName();
    return this.getPartRef()[printName]["Material"];
  }
  getDrwPullStyle() {
    // @TODO:  can we set this as a variable the first time this runs and then refer to that variable any all subsequent runs?
    if (this.drwPullStyle) return this.drwPullStyle;
    const SectAC = this.attr("SectAC"); // "A" or "C"
    const frontFaceValue = this.getFrontFace().attr("Pull" + SectAC); // @TODO: Confirm possible values for PullA and PullC - default seems to be "0"
    if (isNaN(frontFaceValue)) return frontFaceValue;
    const productValue = this.getProductAttribute("DrwPullOR");
    const roomValue = this.getRoomSetting("DrwPulls");
    const drwPullStyle = productValue || roomValue;
    this.drwPullStyle = drwPullStyle;
    return drwPullStyle;
  }
  getQuantity() {
    return this.getStyle() ? 1 : 0;
  }
}

export class DrawerBoxSides extends Drawer {
  constructor(PartElement, Product, DrawerSide) {
    super(PartElement, Product);
    this.DrawerSideElement = DrawerSide;
  }
  getPrintName() {
    return "Drw Side";
  }
  getMaterial() {
    const sectAC = this.attr("SectAC"); // "A" or "C"
    const frontFaceValue = this.getFrontFace().attr("MatDrawerOR" + sectAC);
    const productValue = this.getProductAttribute("MatDrawerOR");
    const roomValue = this.getRoomSetting("MatDrawerTemplate");
    const material = frontFaceValue || productValue || roomValue;
    return material.replace("0.75", '3/4"').replace("3-4 ", '3/4"').replace("0.625", '5/8"');
  }
  getQuantity() {
    return 2;
  }
  getDrawerBoxHeight() {
    // File drawers must have a drawer box that is 238mm in height
    if (this.getBoxStyle().includes("File Drw")) return 238;
    const drawerFace = this.getDrawerFace();
    const drawerFaceHeightRef = drawerFace.getDrawerFaceHeightRef();
    const drawerFaceHeight = drawerFace.getDrawerFaceHeight();
    const drawerBoxHeight = drawerFaceHeightRef[drawerFaceHeight];
    return drawerBoxHeight ? drawerBoxHeight : Math.floor(this.DrawerSideElement.attr("W"));
  }
  getWidth() {
    return Math.floor(this.getDrawerBoxHeight());
  }
  getLength() {
    return Math.floor(this.DrawerSideElement.attr("L"));
  }
}

export class DrawerBoxFrontBack extends Drawer {
  constructor(PartElement, Product, DrawerFront) {
    super(PartElement, Product);
    this.DrawerFrontElement = DrawerFront;
  }
  getPrintName() {
    return "Drw Front / Back";
  }
  getMaterial() {
    const sectAC = this.attr("SectAC"); // "A" or "C"
    const frontFaceValue = this.getFrontFace().attr("MatDrawerOR" + sectAC);
    const productValue = this.getProductAttribute("MatDrawerOR");
    const roomValue = this.getRoomSetting("MatDrawerTemplate");
    const material = frontFaceValue || productValue || roomValue;
    return material.replace("0.75", '3/4"').replace("3-4 ", '3/4"').replace("0.625", '5/8"');
  }
  getQuantity() {
    return 2;
  }
  getDrawerBoxHeight() {
    // File drawers must have a drawer box that is 238mm in height
    if (this.getBoxStyle().includes("File Drw")) return 238;
    const drawerFace = this.getDrawerFace();
    const drawerFaceHeightRef = drawerFace.getDrawerFaceHeightRef();
    const drawerFaceHeight = drawerFace.getDrawerFaceHeight();
    const drawerBoxHeight = drawerFaceHeightRef[drawerFaceHeight];
    return drawerBoxHeight ? drawerBoxHeight : Math.floor(this.DrawerFrontElement.attr("W"));
  }
  getWidth() {
    return Math.floor(this.getDrawerBoxHeight());
  }
  getLength() {
    return Math.floor(this.DrawerFrontElement.attr("L"));
  }
}

export class DrawerBoxBottom extends Drawer {
  constructor(PartElement, Product, DrawerBottom) {
    super(PartElement, Product);
    this.DrawerBottomElement = DrawerBottom;
  }
  getPrintName() {
    return "Drw Bottom";
  }
  // getMaterial() {
  //   return this.getBoxStyle() == "Solid Bottom" ? '5/8" White (L191)' : "Masonite | White 1 Side";
  // }
  getMaterial() {
    const sectAC = this.attr("SectAC"); // "A" or "C"
    const frontFaceValue = this.getFrontFace().attr("MatDrawerOR" + sectAC);
    const productValue = this.getProductAttribute("MatDrawerOR");
    const roomValue = this.getRoomSetting("MatDrawerTemplate");
    const material = frontFaceValue || productValue || roomValue;
    return material.replace("0.75", '3/4"').replace("3-4 ", '3/4"').replace("0.625", '5/8"');
  }
  getWidth() {
    return Math.floor(this.DrawerBottomElement.attr("W"));
  }
  getLength() {
    return Math.floor(this.DrawerBottomElement.attr("L"));
  }
}

export class DrawerSlides extends Drawer {
  constructor(PartElement, Product, DrawerSide) {
    super(PartElement, Product);
    this.DrawerSideElement = DrawerSide;
  }
  getPrintName() {
    return this.getSlideName();
  }
  getMaterial() {
    const printName = this.getPrintName();
    return this.getPartRef()[printName]["Material"];
  }
  getSlideName() {
    const boxStyle = this.getBoxStyle();
    if (boxStyle.startsWith("Legal File")) return 'Slide | Soft Close | 18"';
    if (boxStyle.startsWith("Letter File")) return 'Slide | Soft Close | 14"';

    const BBNH = Number(this.getMozaikParam("BBNH"));
    const sideL = Math.floor(this.DrawerSideElement.attr("L"));
    const Z = Number(this.DrawerSideElement.attr("Z"));

    if (Z < BBNH) return this.getSlideType(sideL);
    // if drawer overlaps the baseboard notch, then don't add anything to side length
    else return this.getSlideType(sideL + (0.875).to("mm")); // else add 7/8" to the side length
  }
  getSlideType(sideL) {
    // https://docs.google.com/spreadsheets/d/1Y_z4UBHkGduOzmW_37kIVKnM9Bu1kephiW1f8X8JT6c/edit#gid=104635460
    const slideRef = this.getSlideRef();
    const key = roundDownToArrayValue(sideL, Object.keys(slideRef));
    return slideRef[key];
  }
  getStyle() {
    return this.getSlideName();
  }
  getQuantity() {
    // each drawer has two slides (or 1 set)
    return 2;
  }
  getLength() {
    const slide = this.getSlideName();
    return slide
      .slice(slide.length - 3)
      .exclude('"')
      .to("mm");
  }
  getWidth() {
    return 0;
  }
}

export class FileRails extends Drawer {
  constructor(PartElement, Product, DrawerSide) {
    super(PartElement, Product);
    this.DrawerSideElement = DrawerSide;
  }
  getPrintName() {
    return "Filing Insert Rail";
  }
  getStyle() {
    return this.getBoxStyle().includes("Horizontal") ? "Horizontal" : "Vertical";
  }
  getRailLength() {
    const style = this.getStyle();
    const boxStyle = this.getBoxStyle();
    const sideL = Math.floor(this.DrawerSideElement.attr("L")).to("inches");
    if (style === "Vertical") return sideL - 1.6875;
    else if (boxStyle.startsWith("Legal File")) return 11.8125;
    else if (boxStyle.startsWith("Letter File")) return 14.8125;
    else return 0;
  }
  getLength() {
    return this.getRailLength();
  }
  getWidth() {
    return 0;
  }
  getQuantity() {
    return this.getBoxStyle().includes("File Drw") ? 2 : 0;
  }
}

export class FileCorners extends Drawer {
  getPrintName() {
    return "Filing Insert Corner";
  }
  getStyle() {
    return this.getBoxStyle().includes("Horizontal") ? "Horizontal" : "Vertical";
  }
  getQuantity() {
    return this.getBoxStyle().includes("File Drw") ? 4 : 0;
  }
  getWidth() {
    return 0;
  }
  getLength() {
    return 0;
  }
}
export class DrawerDividers extends Drawer {
  constructor(PartElement, Product, DrawerSide) {
    super(PartElement, Product);
    this.DrawerSideElement = DrawerSide;
  }
  getPrintName() {
    return "Drawer Divider";
  }
  getWidth() {
    return Math.floor(this.DrawerSideElement.attr("W")) - 31.75;
  }
  getLength() {
    return Math.floor(this.DrawerSideElement.attr("L")) - 16;
  }
  getStyle() {
    return this.getPrintName();
  }
  getQuantity() {
    const boxStyle = this.getBoxStyle();
    return boxStyle.includes("Divider") ? boxStyle.extract(/([0-9])/) || 1 : 0;
  }
  getMaterial() {
    return '5/8" White (L191)';
  }
}

export class DrawerLock extends Drawer {
  getPrintName() {
    return "Drawer Lock";
  }
  getStyle() {
    return this.getPrintName();
  }
  getQuantity() {
    return this.getBoxStyle()?.toLowerCase()?.includes("lock") ? 1 : 0;
  }
  getWidth() {
    return 0;
  }
  getLength() {
    return 0;
  }
}

export class DrawerFelt extends Drawer {
  getPrintName() {
    return "Drawer Felt";
  }
  getStyle() {
    return this.getPrintName();
  }
  getQuantity() {
    return this.getBoxStyle().includes("Felt Drw") ? 1 : 0;
  }
  getWidth() {
    return 0;
  }
  getLength() {
    return 0;
  }
}

export class JewelryInsert extends Drawer {
  getPrintName() {
    return "Jewelry Insert";
  }
  getStyle() {
    return this.getPrintName();
  }
  getQuantity() {
    return this.getBoxStyle().includes("Jewelry Drw") ? 1 : 0;
  }
  getWidth() {
    return 0;
  }
  getLength() {
    return 0;
  }
}
